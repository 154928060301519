import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241')
];

export const server_loads = [0,28,25,4,6,7,10,12,29,15];

export const dictionary = {
		"/(marketing)": [171,[19]],
		"/(marketing)/about": [172,[19,20]],
		"/(admin)/admin": [38,[2]],
		"/(admin)/admin/abundant/add-update": [~39,[2]],
		"/(admin)/admin/abundant/analyze-perf": [~40,[2]],
		"/(admin)/admin/abundant/backup-to-cloud-storage": [~41,[2]],
		"/(admin)/admin/abundant/document-validation": [~42,[2]],
		"/(admin)/admin/abundant/duplicate-risk-type": [~43,[2]],
		"/(admin)/admin/abundant/evict-file-cache": [~44,[2]],
		"/(admin)/admin/abundant/groups": [~45,[2]],
		"/(admin)/admin/abundant/migrate-db": [~46,[2]],
		"/(admin)/admin/abundant/open-api": [47,[2]],
		"/(admin)/admin/abundant/queries-sg": [~48,[2]],
		"/(admin)/admin/abundant/reset-db": [~49,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-db": [~50,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-disk": [~51,[2]],
		"/(admin)/admin/abundant/sync-validation-to-db": [~52,[2]],
		"/(admin)/admin/ai/extract-recommendations": [~53,[2]],
		"/(admin)/admin/categories/add": [~54,[2]],
		"/(admin)/admin/charts/bar": [~55,[2]],
		"/(admin)/admin/charts/map": [56,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar-month": [~58,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar": [~57,[2]],
		"/(admin)/admin/dev-ops/analytics.items-full-load": [~59,[2]],
		"/(admin)/admin/dev-ops/analytics.items-incremental-load": [~60,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-day-full-load": [~61,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-full-load": [~62,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-incremental-load": [~63,[2]],
		"/(admin)/admin/dev-ops/find-unused-files": [~64,[2]],
		"/(admin)/admin/dev-ops/run-pipelines": [~65,[2]],
		"/(admin)/admin/dev-ops/seed-group-data": [~66,[2]],
		"/(admin)/admin/insurance/import-arla": [~67,[2]],
		"/(admin)/admin/insurance/validate": [~68,[2]],
		"/(admin)/admin/misc/add-analytics-board": [~69,[2]],
		"/(admin)/admin/misc/create-thumbnails-by-id": [~70,[2]],
		"/(admin)/admin/misc/customer-scripts": [~71,[2]],
		"/(admin)/admin/misc/get-asset-info": [~72,[2]],
		"/(admin)/admin/misc/inspect-devalue": [73,[2]],
		"/(admin)/admin/misc/query-sdk-rnd": [~74,[2]],
		"/(admin)/admin/misc/sendgrid-delete-contacts": [~75,[2]],
		"/(admin)/admin/risk-data/copy-risk-model-to-prod": [~76,[2]],
		"/(admin)/admin/risk-data/copy-risk-type": [~77,[2]],
		"/(admin)/admin/test-email": [~78,[2]],
		"/(admin)/admin/users/assign-permissions": [~79,[2]],
		"/(admin)/admin/users/delete": [~80,[2]],
		"/(admin)/admin/users/end-session": [~81,[2]],
		"/(admin)/admin/users/seed": [~82,[2]],
		"/(admin)/admin/users/set-user-tasks": [~83,[2]],
		"/(admin)/admin/users/toggle-21risk-admin": [~84,[2]],
		"/(marketing)/blog": [173,[19,21]],
		"/(marketing)/blog/[slug]": [174,[19,21]],
		"/(marketing)/changelog": [175,[19]],
		"/(marketing)/changelog/[slug]": [176,[19]],
		"/(marketing)/checklists": [~177,[19]],
		"/(marketing)/checklists/success": [181,[19]],
		"/(marketing)/checklists/view": [182,[19]],
		"/(marketing)/checklists/[_id=objectId]": [~178,[19]],
		"/(marketing)/checklists/[_id=objectId]/print": [~179,[19]],
		"/(marketing)/checklists/[_id=objectId]/request": [~180,[19]],
		"/(docs)/compliance": [165,[18]],
		"/(docs)/compliance/[...rest]": [~166,[18]],
		"/(marketing)/contact": [183,[19]],
		"/(marketing)/contact/sales": [~184,[19]],
		"/(marketing)/contact/sales/success": [185,[19]],
		"/(marketing)/customers": [186,[19,22]],
		"/(marketing)/customers/BHJ": [187,[19,22]],
		"/(marketing)/customers/[slug]": [188,[19,22]],
		"/(docs)/docs": [167,[18]],
		"/(docs)/docs/concepts": [169,[18]],
		"/(docs)/docs/concepts/[...rest]": [170,[18]],
		"/(docs)/docs/[...rest]": [~168,[18]],
		"/(reset)/emails/preview/[templateId]/[sendgridPayload]": [~222,[28]],
		"/(marketing)/experts": [189,[19,23]],
		"/(marketing)/experts/[slug]": [~190,[19,23,24]],
		"/(marketing)/experts/[slug]/success": [191,[19,23,24]],
		"/(marketing)/features/actions": [192,[19]],
		"/(marketing)/features/audits": [193,[19]],
		"/(unauthed)/feedback/emails/thank-you": [231,[32,33]],
		"/(unauthed)/feedback/emails/[emailId]": [~230,[32,33]],
		"/(marketing)/health": [194,[19]],
		"/(marketing)/help": [~195,[19,25]],
		"/(marketing)/help/success": [196,[19,25]],
		"/(marketing)/legal": [197,[19]],
		"/(marketing)/legal/[slug]": [198,[19]],
		"/(unauthed)/library-public": [232,[32]],
		"/(unauthed-prerender)/login": [~236,[34,35]],
		"/(unauthed-prerender)/login/check": [~237,[34,35]],
		"/(unauthed-prerender)/logout": [~238,[34]],
		"/(admin)/mgmt": [85,[3]],
		"/(admin)/mgmt/analytics/overview": [~86,[3]],
		"/(unauthed)/misc/debug": [233,[32]],
		"/(unauthed)/misc/files-list": [~234,[32]],
		"/(marketing)/newsletter/check": [199,[19]],
		"/(marketing)/newsletter/subscribe": [~200,[19]],
		"/(marketing)/newsletter/success": [201,[19]],
		"/(marketing)/newsletter/verify": [~202,[19]],
		"/(unauthed)/no-access/[tenant_without_access=org_slug]": [~235,[32]],
		"/(reset)/onboarding": [223,[28,30]],
		"/(reset)/onboarding/1": [224,[28,30]],
		"/(reset)/onboarding/2": [~225,[28,30]],
		"/(reset)/onboarding/3": [~226,[28,30]],
		"/(reset)/onboarding/4": [227,[28,30]],
		"/(marketing)/partners": [~203,[19]],
		"/(marketing)/partners/success": [204,[19]],
		"/(marketing)/pricing": [205,[19]],
		"/(app)/report/[_id=objectId]": [~164],
		"/(marketing)/roadmap": [206,[19]],
		"/(unauthed-prerender)/signup": [~239,[34,36]],
		"/(unauthed-prerender)/signup/check": [~240,[34,36]],
		"/(unauthed-prerender)/signup/complete": [~241,[34,36]],
		"/(marketing)/solutions/compliance": [207,[19]],
		"/(marketing)/solutions/insurance-values": [208,[19]],
		"/(marketing)/solutions/natcat": [209,[19]],
		"/(marketing)/solutions/natcat/[hazard]": [~210,[19]],
		"/(marketing)/solutions/property-insurance": [~211,[19]],
		"/(marketing)/solutions/property-insurance/roi-breakdown": [~212,[19]],
		"/(marketing)/solutions/property-insurance/roi-breakdown/success": [213,[19]],
		"/(marketing)/solutions/property-insurance/roi-calculator": [~214,[19]],
		"/(stories)/stories": [228,[31]],
		"/(stories)/stories/[...story]": [~229,[31]],
		"/(marketing)/updates": [~215,[19]],
		"/(marketing)/updates/[slug]": [~216,[19]],
		"/(marketing)/whitepapers": [217,[19,26]],
		"/(marketing)/whitepapers/success": [219,[19,26]],
		"/(marketing)/whitepapers/view/[uuid]": [220,[19,26]],
		"/(marketing)/whitepapers/[slug]": [~218,[19,26,27]],
		"/(app)/[tenant=org_slug]": [88,[4]],
		"/(app)/[tenant=org_slug]/actions": [~90,[4]],
		"/(app)/[tenant=org_slug]/actions/board": [92,[4]],
		"/(app)/[tenant=org_slug]/actions/table": [93,[4]],
		"/(app)/[tenant=org_slug]/actions/[view]": [91,[4]],
		"/(app)/[tenant=org_slug]/action/[itemId]": [~89,[4]],
		"/(app)/[tenant=org_slug]/analytics/[board=board_slug]/[[page]]": [~94,[4]],
		"/(app)/[tenant=org_slug]/compliance": [95,[4]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]": [96,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions": [97,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/board": [98,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings": [99,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings/fields/new": [~101,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings/fields/[field]": [~100,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/table": [~102,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/analytics": [~103,[4,5,8]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/analytics/empty": [104,[4,5,8]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit": [~105,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/automations/new": [~111,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/automations/[_id=objectId]": [~110,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]": [~106,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations": [~107,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations/new": [~109,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations/[_id=objectId]": [~108,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports": [~112,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/new": [~115,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/request-audit": [~116,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings": [117,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings/fields/new": [~119,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings/fields/[field]": [~118,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/[_id=objectId]": [~113,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/[_id=objectId]/qr-code": [~114,[4,5,9]],
		"/(app)/[tenant=org_slug]/file-upload": [~120,[4]],
		"/(app)/[tenant=org_slug]/folder/[board=board_slug]": [121,[4,11]],
		"/(app)/[tenant=org_slug]/folder/[board=board_slug]/edit": [~122,[4,11]],
		"/(app)/[tenant=org_slug]/getting-started": [~123,[4]],
		"/(app)/[tenant=org_slug]/home": [~124,[4]],
		"/(app)/[tenant=org_slug]/insurance/cope": [125,[4]],
		"/(app)/[tenant=org_slug]/insurance/cope/[period]": [126,[4]],
		"/(app)/[tenant=org_slug]/insurance/cope/[period]/[page=cope_page]": [~127,[4]],
		"/(app)/[tenant=org_slug]/insurance/export": [128,[4]],
		"/(app)/[tenant=org_slug]/insurance/export/[period]": [~129,[4]],
		"/(app)/[tenant=org_slug]/insurance/map": [130,[4]],
		"/(app)/[tenant=org_slug]/insurance/map/[period]": [~131,[4]],
		"/(app)/[tenant=org_slug]/insurance/settings": [~132,[4]],
		"/(app)/[tenant=org_slug]/insurance/settings/[period]": [~133,[4]],
		"/(app)/[tenant=org_slug]/insurance/values": [134,[4]],
		"/(app)/[tenant=org_slug]/insurance/values/[period]": [~135,[4]],
		"/(app)/[tenant=org_slug]/insurance/values/[period]/[siteId=objectId]": [136,[4,12]],
		"/(app)/[tenant=org_slug]/insurance/values/[period]/[siteId=objectId]/changes": [137,[4,12]],
		"/(app)/[tenant=org_slug]/insurance/values/[period]/[siteId=objectId]/cope": [~138,[4,12]],
		"/(app)/[tenant=org_slug]/insurance/values/[period]/[siteId=objectId]/overview": [139,[4,12]],
		"/(app)/[tenant=org_slug]/insurance/values/[period]/[siteId=objectId]/values": [~140,[4,12]],
		"/(app)/[tenant=org_slug]/new/board": [~141,[4]],
		"/(app)/[tenant=org_slug]/new/folder": [~142,[4]],
		"/(app)/[tenant=org_slug]/new/model": [~143,[4]],
		"/(reset)/[tenant=org_slug]/print/[_id=objectId]": [~221,[28,29]],
		"/(app)/[tenant=org_slug]/reports": [~144,[4]],
		"/(app)/[tenant=org_slug]/reports/[view]": [145,[4]],
		"/(app)/[tenant=org_slug]/settings/account": [~146,[4,13]],
		"/(app)/[tenant=org_slug]/settings/account/balance-changes": [~147,[4,13]],
		"/(app)/[tenant=org_slug]/settings/account/roles/[roleId]": [~148,[4,13]],
		"/(app)/[tenant=org_slug]/settings/profile": [~149,[4,13]],
		"/(app)/[tenant=org_slug]/sites": [~150,[4,14]],
		"/(app)/[tenant=org_slug]/sites/import": [~152,[4,14]],
		"/(app)/[tenant=org_slug]/sites/map": [~153,[4,14]],
		"/(app)/[tenant=org_slug]/sites/settings": [154,[4,14,15]],
		"/(app)/[tenant=org_slug]/sites/settings/fields/new": [~156,[4,14,15]],
		"/(app)/[tenant=org_slug]/sites/settings/fields/[field]": [~155,[4,14,15]],
		"/(app)/[tenant=org_slug]/sites/table": [~157,[4,14]],
		"/(app)/[tenant=org_slug]/sites/tree": [~158,[4,14]],
		"/(app)/[tenant=org_slug]/sites/[siteId=objectId]": [~151,[4,14]],
		"/(app)/[tenant=org_slug]/tags": [~159,[4,16]],
		"/(app)/[tenant=org_slug]/tags/[tagId=objectId]": [~160,[4,16]],
		"/(app)/[tenant=org_slug]/users": [~161,[4,17]],
		"/(app)/[tenant=org_slug]/users/new": [~163,[4,17]],
		"/(app)/[tenant=org_slug]/users/[user_id]": [~162,[4,17]],
		"/(app)/[...rest]": [~87]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';